<template>
    <div class="mb-1">
        <ckeditor :value="content" :config="editorConfig" :editor="editor" @input="emitInput"/>
    </div>
</template>

<script>
import '@/libs/ckeditor/ckeditor'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

export default {
    name: "CKeditor",

    props: {
        // eslint-disable-next-line vue/require-default-prop
        content: {
            required: false,
            type: String
        }
    },

    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                language: 'fa'
            },
        }
    },
    methods: {
        emitInput(value) {
            this.$emit('input', value)
        },
    }
}
</script>
<style>
.ck .ck-content{
    height: 200px;
}
</style>